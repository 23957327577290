<template>
	<div>
        <v-card class="pa-5">
            <v-row class="px-2">
                <v-col cols="12">
                    <h3 class="text-center my-0">{{$t('assets.assetAnalysis')}}</h3>
                </v-col>
                <v-col cols="12">
                    <div class="font-3x fw-bold">
                        <span>{{ analysisData.totalValue }}</span>
                        <span v-if="analysisData.performance != null" class="mr-5 float-right" :class="[analysisData.performance == '--' ? '' : (analysisData.performance > 0 ? 'green--text' : 'red--text')]">{{ analysisData.performance == '--' ? '' : (analysisData.performance > 0 ? ('+' + CommonJS.formatNumber(analysisData.performance.toFixed(2)) + '%') : (CommonJS.formatNumber(analysisData.performance.toFixed(2)) + '%')) }}
                        </span>

                    </div>
                </v-col>
                <v-col cols="12" md="6">
                   <daily-benefit-column-chart></daily-benefit-column-chart> 
                </v-col>
                <v-col cols="12" md="6">
                    <assets-trend-line-chart @sendAnalysisData="receiveAnalysisData"></assets-trend-line-chart>
                </v-col>
                <v-col cols="12">
                    <div class="mt-8 fs-14 grey--text float-right">{{ $t('assets.dataStatisticsTime') }}: {{ formatDateTime(analysisData.dataUpdateTimestamp) }}</div>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import AssetsTrendLineChart from "@/highcharts/AssetsTrendLineChart.vue";
    import DailyBenefitColumnChart from "@/highcharts/asset/DailyBenefitColumnChart";
    export default {
        data(){
            return {
                analysisData: {}
            }
        },
        components: {
            AssetsTrendLineChart, DailyBenefitColumnChart
        },
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {
            // 接受数据
            receiveAnalysisData(data){
                this.analysisData = data;
            },
            // 格式化时间
            formatDateTime(timestamp){
                if('UTCTime' == this.time){
                    return formatUTCTime(timestamp);
                }else{
                    return formatTime(timestamp);
                }
            },
        },
    }
</script>
