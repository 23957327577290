<template>
	<div>
        <v-card class="pa-5">
            <v-row class="px-2">
                <v-col cols="12">
                    <h3 class="text-center my-0">{{$t('assets.assetAllocation')}}</h3>
                </v-col>
                <v-col cols="12" md="6">
                    <exchange-asset-allocation-pie-chart></exchange-asset-allocation-pie-chart>
                </v-col>
                <v-col cols="12" md="6">
                    <category-asset-allocation-pie-chart></category-asset-allocation-pie-chart>
                </v-col>
            </v-row>
        </v-card>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import ExchangeAssetAllocationPieChart from '@/highcharts/asset/ExchangeAssetAllocationPieChart.vue';
    import CategoryAssetAllocationPieChart from '@/highcharts/asset/CategoryAssetAllocationPieChart.vue';
    export default {
        data(){
            return {

            }
        },
        components: {
            ExchangeAssetAllocationPieChart, CategoryAssetAllocationPieChart
        },
        created(){

        },
        mounted(){

        },
        computed: {

        },
        watch:{

        },
        methods: {

        },
    }
</script>
