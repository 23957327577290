<template>
	<div>
        <div class="text-center">
            <h5>{{ $t('assets.exchangeAssetAllocation') }}</h5>
        </div>
        <div v-if="responsive == 'PC'" :id="id" style="height: 360px"></div>
        <div v-if="responsive != 'PC'" :id="id" style="height: 240px"></div>
	</div>
</template>
<style>

</style>
<script>
    import Vue from 'vue'
    import config from '@/config.js';
    import Highcharts from 'highcharts/highstock';
    import { mapGetters } from "vuex";
    export default {
        data(){
            return {
                id: 'exchangeAssetAllocationPieChart',
                option: {
                    chart: {
                        backgroundColor: this.darkMode ? 'dark' : '',
                        renderTo: 'exchangeAssetAllocationPieChart',
                        type: 'pie'
                    },
                    title: {
                        text: null
                    },
                    tooltip: {
                        pointFormat: 'Total Value: ${point.totalValue:.2f}<br/>Percentage: <b>{point.percentage:.2f}%</b>'
                    },
                    plotOptions: {
                        pie: {
                            allowPointSelect: true,
                            cursor: 'pointer',
                            dataLabels: {
                                enabled: true,
                                distance: 0,
                                format: '<b>{point.name}</b>: {point.percentage:.2f} %',
                                style: {
                                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black'
                                }
                            },
                            showInLegend: true
                        }
                    },
                    series: [{
                        colorByPoint: true,
                        innerSize: '50%',
                        data: null
                    }],
                    // 图例导航
                    legend: {
                        enabled: true,
                        itemStyle: {
                            color: '#999999'
                        },
                        itemHoverStyle: {
                            color: '#999999'
                        },
                        itemHiddenStyle: {
                            color: '#606063'
                        },
                        title: {
                            style: {
                                color: '#C0C0C0',
                                fontFamily: '\'Lexend Deca\', sans-serif'
                            }
                        }
                    },
                    noData: {
                        style: {
                            fontWeight: 'bold',
                            fontSize: '15px',
                            color: '#303030'
                        }
                    },
                    // 版权信息
                    credits: {
                        enabled: false
                    },
                    // 导出图片
                    exporting: {
                        enabled: false,
                        filename: 'Excgange-Assets-Allocation-Pie-Chart',
                        buttons: {
                            contextButton: {
                                align: "left",
                                verticalAlign: "top",
                                x: 1,
                                y: 0
                            }
                        }
                    },
                }
            }
        },
        created(){
            this.getAssetAllocationAtExchange();
        },
        mounted(){
            // 不是PC版不显示提示信息
            if (this.responsive != 'PC') {
                this.option.plotOptions.pie.dataLabels.enabled = false;
            }
            Highcharts.chart(this.id, this.option);
        },
        computed: {
            ...mapGetters(['darkMode', 'responsive'])
        },
        watch:{

        },
        methods: {
            getAssetAllocationAtExchange(){
                let api = config.baseUrl + '/asset/analysis/private/allocation/exchange';
                this.$http.get(api).then(res => {
                    let data = res.data;
                    if (data.code == 200) {
                        let allocationDataList = data.result.data;
                        for(let i = 0, len = allocationDataList.length; i < len; i++){
                            let name = allocationDataList[i].name.toLowerCase();
                            let color = null;
                            switch(name){
                                case 'kraken': color = '#9785fb'; break;
                                case 'okex': color = '#3075ee'; break;
                                case 'binance': color = '#F0B90B'; break;
                                default: break;
                            }
                            allocationDataList[i].color = color;
                        }
                        this.option.series[0].data = allocationDataList;
                        Highcharts.chart(this.id, this.option);
                    } 
                }, error => {

                });
            }
        },
    }
</script>
