<template>
  <div>
    <div :id="id" style="height: 480px"></div>
  </div>
</template>
<style>
 svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
        // id: 'assetsHistogram',
        option:{
          colors: ['#95CEFF'],
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'assetsHistogram',
            type: 'column',
            // 3D模型参数
            // options3d: {
            //   enabled: true,
            //   alpha: 10,
            //   beta: 20,
            //   depth: 70,
            //   viewDistance: 50
            // }
          },
          // 标题
          title: {
            text: null
          },
          // 图例导航
          legend: {
            enabled: false
          },
          // 图形区域参数
          plotOptions: {
            column: {
              depth: 25,
			  borderColor: "",//去边框
            }
          },
          // X轴
          xAxis: {
            // categories: ['USDT','USD', 'BTC','BCH', 'ETH',  'LTC', 'XRP','EOS', 'ETC','BSV','TRX',"OKB",'DASH', 'NEO','LINK','QTUM', 'ATOM','XTZ','ADA',"XLM",'$Total'],
            categories: [],
            labels: {
                style: {
                    color: '#999999'
                }
            },
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            labels: {
              align: "left",
              x: -10
            },
            offset: 40,
             // 横线颜色
            gridLineWidth: 0,
            "opposite": true
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 2,
            valuePrefix: '$',
            valueSuffix: ' USD',
          },
          // 数据
          series: [{
            name: this.$t('highcharts.assetsHistogram.assets'),
            // data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            data: [],
			color: {
			            linearGradient: {
			              x1: 0,
			              x2: 0,
			              y1: 0,
			              y2: 1
			            },
			            stops: [
			              [0, '#4CAF50'],
			              [1, '#FEE108']
			            ]
			          },
            dataLabels: {
              enabled: true,
              format: '${point.y:.2f}',
              style: {
                color: '#999999',
                textOutline:'0'
              }
			  
            }
          }],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          navigation: {
            buttonOptions: {
                symbolStroke: '#ffffff',
                theme: {
                    fill: this.darkMode ? '' : 'black',
                },
            }
          },
          // 版权信息
          credits: {
            enabled: false,
            href: config.domainName,
            text: this.$t('highcharts.dataFromIOB')
          },
          exporting: {
            enabled: false,
            filename: 'Assets-Histogram',
            buttons: {
                contextButton: {
                  align: "left",
                  verticalAlign: "top",
                  x: 1,
                  y: 0
                }
              }
          }
        },
      }
    },
    props: {
      seriesData: {
        type: Array,
        // default: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        default: [],
      },
      id: {
        type: String,
        default: null
      },
      seriesCategory:{
        type: Array,
        default:[]
      }
    },
    watch: {
      seriesData(newVal, oldVal){
        if(this.seriesCategory.length > 0 ){
          let categoryData = [];
          let valueData = [];
          for(let i = 0, len = newVal.length; i < len; i++){
            if (newVal[i] > 0) {
              categoryData.push(this.seriesCategory[i]);
              valueData.push(newVal[i]);
            }
          }
          this.option.series[0].data = valueData;
          this.option.xAxis.categories = categoryData;
          // 重新渲染图表
          Highcharts.chart(this.id, this.option);
        }else{
          this.option.series[0].data = [];
          this.option.xAxis.categories = [];
          Highcharts.chart(this.id, this.option);
        }
        // this.$refs.highchartComponent.refresh();
      },
      darkMode(val){
        this.option.navigation.buttonOptions.symbolStroke = val ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = val ? '#424242':'white';
        Highcharts.chart(this.id, this.option);
      }
    },
    mounted(){
      this.getAssetsTrend();
      Highcharts.setOptions({ lang: {
        noData: this.$t('highcharts.noData'),
        contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
        printChart: this.$t('highcharts.printChart'),
        resetZoom: this.$t('highcharts.resetZoom'),
        resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
        downloadPNG: this.$t('highcharts.downloadPNG'),
        downloadJPEG: this.$t('highcharts.downloadJPEG'),
        downloadPDF: this.$t('highcharts.downloadPDF'),
        downloadSVG: this.$t('highcharts.downloadSVG')
      }});
      Highcharts.chart(this.id, this.option);
    },
    computed: {
      ...mapGetters(['responsive', 'darkMode'])
    },
    components: {

    },
    methods:{
      getAssetsTrend(){
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#424242':'white';
        Highcharts.chart(this.id, this.option);
      }
    }
  }
</script>
