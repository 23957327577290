<template>
  <div>
    <v-card class="pa-5">
    <v-row class="px-2">
      <v-col cols="12">
          <h3 class="text-center my-0">{{$t('assets.positionsAndBalance')}}</h3>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6" xs="6">
        <h5 class="text-center mb-5">{{ $t('assets.swapCoinPositions') }}</h5>
        <v-data-table dense hide-default-footer hide-default-header disable-pagination>
          <template v-slot:header>
            <thead>
              <tr>
                <th width="20%">{{ $t('assets.currency') }}</th>
                <th width="40%" class="text-right"> {{ $t('assets.assetNet') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.plNet') }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:body>
            <tbody v-if="coinPosition.length > 0">
              <tr v-for="(item, i) in coinPosition" :key="i" v-if="CommonJS.likeMatch(item.currency, assetsSearchCoinFilter)">
                <td>{{item.currency}}</td>
                <td class="text-right" :class="[item.position < 0 ? 'red--text' : '']">{{ "$" + formatNum(item.position.toFixed(2)) }}</td>
                <td class="text-right" :class="[item.pl < 0 ? 'red--text' : '']">{{ ( 0 > item.pl ? '($' : '$') + formatNum(Math.abs(item.pl.toFixed(2))) + ( 0 > item.pl ? ')' : '')}}</td>
              </tr>
              <tr>
                <td>{{ $t('assets.total')}}</td>
                <td class="text-right" :class="[totalCoinPosition < 0 ? 'red--text' : '']">{{ "$" + formatNum(totalCoinPosition) }}</td>
                <td class="text-right" :class="[totalCoinPl < 0 ? 'red--text' : '']">{{ ( 0 > totalCoinPl ? '($' : '$') + formatNum(Math.abs(totalCoinPl)) + ( 0 > totalCoinPl ? ')' : '')}}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                  <td colspan="3" class="text-center">{{ $t('assets.noPositions') }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6" xs="6">
        <h5 class="text-center mb-5">{{ $t('assets.swapUsdtPositions')}}</h5>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:header>
            <thead>
              <tr>
                <th width="20%">{{ $t('assets.currency') }}</th>
                <th width="40%" class="text-right"> {{ $t('assets.assetNet') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.plNet') }}</th>
              </tr>
            </thead>
          </template>
            <template v-slot:body>
              <tbody v-if="usdtPosition.length > 0">
                <tr v-for="(item, i) in usdtPosition" :key="i" v-if="CommonJS.likeMatch(item.currency, assetsSearchCoinFilter)">
                  <td>{{item.currency}}</td>
                  <td class="text-right" :class="[item.position < 0 ? 'red--text' : '']">{{ "₮" + formatNum(item.position.toFixed(2)) }}</td>
                  <td class="text-right" :class="[item.pl < 0 ? 'red--text' : '']">{{ ( 0 > item.pl ? '(₮' : '') + formatNum(Math.abs(item.pl.toFixed(2))) + ( 0 > item.pl ? ')' : '')}}</td>
                </tr>
                <tr>
                  <td>{{ $t('assets.total')}}</td>
                  <td class="text-right" :class="[totalusdtPosition < 0 ? 'red--text' : '']">{{ "₮" + formatNum(totalusdtPosition) }}</td>
                  <td class="text-right" :class="[totalusdtPl < 0 ? 'red--text' : '']">{{ ( 0 > totalusdtPl ? '(₮' : '₮') + formatNum(Math.abs(totalusdtPl)) + ( 0 > totalusdtPl ? ')' : '')}}</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="3" class="text-center">{{ $t('assets.noPositions') }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6" xs="6">
        <h5 class="text-center mb-5">{{ $t("assets.swapCoinBalance") }}</h5>
        <v-data-table dense hide-default-footer hide-default-header disable-pagination>
          <template v-slot:header>
            <thead>
              <tr>
                <th width="20%">{{ $t('assets.currency') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.amount') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.value') }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:body>
            <tbody v-if="coinBalance.length > 0">
              <tr v-for="(item, i) in coinBalance" :key="i" v-if="CommonJS.likeMatch(item.currency, assetsSearchCoinFilter)">
                <td>{{item.currency}}</td>
                <td class="text-right">{{ formatNum(item.amount.toFixed(6)) }}</td>
                <td class="text-right">{{ "$" + formatNum(item.value.toFixed(2)) }}</td>
              </tr>
              <tr>
                <td>{{ $t('assets.total')}}</td>
                <td></td>
                <td class="text-right">{{ "$" + formatNum(totalCoinValue) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">{{ $t("assets.noBalance") }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="6" xs="6">
        <h5 class="text-center mb-5">{{ $t("assets.swapUsdtBalance") }}</h5>
        <v-data-table dense hide-default-footer hide-default-header disable-pagination>
          <template v-slot:header>
            <thead>
              <tr>
                <th width="20%">{{ $t('assets.currency') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.amount') }}</th>
                <th width="40%" class="text-right">{{ $t('assets.value') }}</th>
              </tr>
            </thead>
          </template>
          <template v-slot:body>
            <tbody v-if="usdtBalance.length > 0">
              <tr v-for="(item, i) in usdtBalance" :key="i" v-if="CommonJS.likeMatch(item.currency, assetsSearchCoinFilter)">
                <td>{{item.currency}}</td>
                <td class="text-right">{{ "₮" + formatNum(item.amount.toFixed(2)) }}</td>
                <td class="text-right">{{ "$" + formatNum(item.value.toFixed(2)) }}</td>
              </tr>
              <tr>
                <td>{{ $t('assets.total')}}</td>
                <td class="text-right">{{ "₮" + formatNum(totalUsdtAmount) }}</td>
                <td class="text-right">{{ "$" + formatNum(totalUsdtValue) }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="3" class="text-center">{{ $t("assets.noBalance") }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
  </div>
</template>

<script>
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import Bus from "@/components/bus/Bus";
  export default {
    data() {
      return {
        totalList:[],
        coinBalance:[],
        usdtBalance:[],
        coinPosition:[],
        usdtPosition:[],
      }
    },
    props: [

    ],
    created() {
    },
    mounted() {
      if(localStorage.getItem("iobots-positonAsset-data") != null){
        let data = JSON.parse(localStorage.getItem("iobots-positonAsset-data"))
        this.coinBalance = data.coinBalance;
        this.usdtBalance = data.usdtBalance;
        this.coinPosition = data.coinPosition;
        this.usdtPosition = data.usdtPosition;
      }
      this.getPositionAssets();
      Bus.$on('refreshAssets', data => {
        this.getPositionAssets();
      })
    },
    computed: {
      ...mapGetters(['futuresDecimalNumbers', 'responsive', 'darkMode', 'assetsSearchCoinFilter']),
      totalCoinValue(){
        var total = 0;
        for(var i = 0 ; i < this.coinBalance.length ; i++){
          total += this.coinBalance[i].value;
        }
        return total.toFixed(2);
      },
      totalUsdtAmount(){
        var total = 0;
        for(var i = 0 ; i < this.usdtBalance.length ; i++){
          total += this.usdtBalance[i].amount;
        }
        return total.toFixed(2);
      },
      totalUsdtValue(){
        var total = 0;
        for(var i = 0 ; i < this.usdtBalance.length ; i++){
          total += this.usdtBalance[i].value;
        }
        return total.toFixed(2);
      },
      totalCoinPosition(){
        var total = 0;
        for(var i = 0 ; i < this.coinPosition.length ; i++){
          total += this.coinPosition[i].position;
        }
        return total.toFixed(2);
      },
      totalCoinPl(){
        var total = 0;
        for(var i = 0 ; i < this.coinPosition.length ; i++){
          total += this.coinPosition[i].pl;
        }
        return total.toFixed(2);
      },
      totalusdtPosition(){
        var total = 0;
        for(var i = 0 ; i < this.usdtPosition.length ; i++){
          total += this.usdtPosition[i].position;
        }
        return total.toFixed(2);
      },
      totalusdtPl(){
        var total = 0;
        for(var i = 0 ; i < this.usdtPosition.length ; i++){
          total += this.usdtPosition[i].pl;
        }
        return total.toFixed(2);
      },
    },
    components:{
    },
    watch: {

    },
    methods: {
      // 获取用户持仓以及期货的总信息
      getPositionAssets(){
        let api = config.baseUrl + "/asset/private/getPositionAssetsV2";
        this.$http.get(api).then(res=>{
          let data = res.data;
          if(data.code == 200){
            if(data.result != null){
              this.coinBalance = data.result.coinBalance;
              this.usdtBalance = data.result.usdtBalance;
              this.coinPosition = data.result.coinPosition;
              this.usdtPosition = data.result.usdtPosition;
              localStorage.setItem("iobots-positonAsset-data",JSON.stringify(data.result))
            }
          }
        })
      },
      // 数字格式化
      formatNum(num) {
        return formatNumber(num);
      },
    },
  }
</script>
