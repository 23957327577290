<template>
  <div>
    <v-container fluid class="grid-list-xl pt-0 mt-12">
      <v-row class="align-center justify-center">
        <!-- 资产总值 -->
        <v-col cols="12" md="9" class="px-0">
          <v-progress-linear :indeterminate="assetLoading" color="blue" height="3" v-if="assetLoading"></v-progress-linear>
          <total-asset  :totalSymbols="totalSymbols" :totalList="totalList" @refreshAssets="getAsset()"></total-asset>
        </v-col>
        <!-- 资产分析 -->
        <v-col cols="12" md="9" class="px-0">
          <asset-analysis></asset-analysis>
        </v-col>
        <!-- 资产分配 -->
        <v-col cols="12" md="9" class="px-0">
          <asset-allocation></asset-allocation>
        </v-col>
        <!-- 持仓资产 -->
        <v-col cols="12" md="9" class="px-0">
          <positions-asset></positions-asset>
        </v-col>
        <!-- 资产详情 -->
        <v-col cols="12" md="9" class="px-0">
          <asset-info :info="info"></asset-info>
        </v-col>
      </v-row>
    </v-container>
    <mobile-btn></mobile-btn>
  </div>
</template>

<script>
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import TotalAsset from "@/components/asset/TotalAsset";
  import AssetInfo from "@/components/asset/AssetInfo";
  import PositionsAsset from "@/components/asset/PositionsAsset";
  import AssetAllocation from "@/components/asset/AssetAllocation";
  import AssetAnalysis from "@/components/asset/AssetAnalysis";
  import MobileBtn from "@/components/common/MobileBtn";
  
  export default {
    data() {
      return {
        totalSymbols:[],
        totalList:[],
        assetLoading:false,
        info:{
          okexSpot:[],
          okexFunding:[],
          okexMargin:[],
          okexFutures:[],
          okexSwap:[],
          okexOptions:[],
          okexSpotTotal:[],
          okexFuturesTotal:[],
          krakenSpot:[],
          krakenFunding:[],
          krakenMargin:[],
          krakenFutures:[],
          krakenSwap:[],
          krakenOptions:[],
          krakenSpotTotal:[],
          krakenFuturesTotal:[],
          binanceSpot:[],
          binanceSwap:[],
          binanceTotal:[]
        }
      }
    },
    components: {
      TotalAsset,
      AssetInfo,
      PositionsAsset,
      AssetAllocation,
      AssetAnalysis,
      MobileBtn
    },
    created() {
      this.isLogin();
    },
    mounted() {
      this.isLogin();
      this.getAsset();
    },
    computed: {
      ...mapGetters(['darkMode', 'responsive', 'user','assetInfo']),
      // 总估值
    },
    watch: {

    },
    methods: {
      // 是否登录
      isLogin() {
        if (!this.user.token) {
          this.$router.push({ name: "signIn" });
          this.$store.dispatch("snackbarMessageHandler", this.$t("signals.signalSnackbarMessage"));
        }
      },
      // 获取用户的资产信息
      getAsset(){
        this.info = this.assetInfo;
        this.assetLoading = true;
        let api = config.baseUrl + "/asset/private/getNewAssets";
        this.$http.get(api).then(res=>{
          let data = res.data;
          if(data.code == 200){
            var symbols = data.result.totalSymbols;
            symbols.push("$ Total");
            this.totalList = data.result.total;
            this.totalSymbols = symbols;
            this.assetLoading = false;
            // this.info.okexSpot = data.result.okexSpot;
            // this.info.okexFunding = data.result.okexFunding;
            // this.info.okexSwap = data.result.okexSwap;
            // this.info.krakenSpot = data.result.krakenSpot;
            // this.info.krakenFunding = data.result.krakenFunding;
            // this.info.krakenSwap = data.result.krakenSwap;
            // this.info.okexSpotTotal = data.result.okexSpotTotal;
            // this.info.okexFuturesTotal = data.result.okexFuturesTotal;
            // this.info.krakenSpotTotal = data.result.krakenSpotTotal;
            // this.info.krakenFuturesTotal = data.result.krakenFuturesTotal;
            this.info = data.result;
            this.$store.dispatch('assetInfoHandler',this.info)
          }else{
            this.assetLoading = false;
          }
        })
      }
    }
  }
</script>
