<template>
  <v-card>
    <v-row class="px-5 pt-5">
      <v-col cols="12">
        <h3 class="text-center my-0">{{$t('assets.totalAssets')}}</h3>
      </v-col>
    </v-row>
    <v-row class="px-5 align-end" v-if="responsive=='PC'">
      <v-col cols="2">
        <v-text-field :label="$t('assets.searchCoin')" v-model="assetsSearchCoinFilterText" hide-details class="pa-0 mb-5"></v-text-field>
      </v-col>
      <v-col cols="10" class="mb-3">
        <!-- 隱藏0余额的币 -->
        <v-btn :title="$t('assets.hideZeroBalances')" icon @click="changeHideZeroBalances">
          <v-icon medium color="lightred" v-if="!hideZeroBalances">mdi-checkbox-blank-outline</v-icon>
          <v-icon medium color="lightred" v-if="hideZeroBalances">mdi-checkbox-marked</v-icon>
        </v-btn>
        <span class="align-end">
          {{ $t('assets.hideZeroBalances') }}
        </span>
        <span class="float-right">
          <!-- 资金流水 -->
          <router-link class="del-underline" to="/user/logs#ledger">
            <v-btn color="lightred" class="black--text" small>
              <span class="text-none">{{ $t('log.ledger') }}</span>
            </v-btn>
          </router-link>
          <!-- 刷新按钮 -->
          <v-btn :title="$t('common.refresh')" icon class="ml-3" @click="refresh">
            <v-icon medium color="lightred">mdi-refresh</v-icon>
          </v-btn>
        </span>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-divider class="my-0"></v-divider>
      </v-col>
    </v-row>
    <v-row class="px-5 align-end" v-if="responsive!='PC'">
      <v-col cols="4" class="py-0">
        <v-text-field :label="$t('assets.searchCoin')" v-model="assetsSearchCoinFilterText" hide-details class="pa-0 mb-5"></v-text-field>
      </v-col>
      <v-col cols="8" class="py-0 mb-3">
        <span class="float-right">
          <!-- 隱藏0余额的币 -->
          <v-btn :title="$t('assets.hideZeroBalances')" icon @click="changeHideZeroBalances">
            <v-icon medium color="lightred" v-if="!hideZeroBalances">mdi-checkbox-blank-outline</v-icon>
            <v-icon medium color="lightred" v-if="hideZeroBalances">mdi-checkbox-marked</v-icon>
          </v-btn>
          <span class="align-end">
            {{ $t('assets.hideZeroBalances') }}
          </span>
        </span>
      </v-col>
      <v-col cols="12" class=" py-0 mb-3">
        <span class="float-right">
          <!-- 资金流水 -->
          <router-link class="del-underline" to="/user/logs#ledger">
            <v-btn color="lightred" class="black--text" small>
              <span class="text-none">{{ $t('log.ledger') }}</span>
            </v-btn>
          </router-link>
          <!-- 刷新按钮 -->
          <v-btn :title="$t('common.refresh')" icon class="ml-3" @click="refresh">
            <v-icon medium color="lightred">mdi-refresh</v-icon>
          </v-btn>
        </span>
      </v-col>
      <v-col cols="12" class="py-0">
        <v-divider class="my-0"></v-divider>
      </v-col>
    </v-row>
    <v-row class="px-5">
      <!-- 柱状图 -->
      <v-col cols="12" sm="6" lg="7" md="12" class="mt-2 mr-15">
        <assets-histogram :seriesData="chartData" :seriesCategory="totalSymbols" :id="id"></assets-histogram>
      </v-col>
      <!-- 列表 -->
      <v-col cols="12" sm="6" lg="4" md="12" class="mt-5">
        <!-- <h5 class="text-center mb-5">{{$t("assets.totalAssets")}}</h5> -->
        <v-data-table dense hide-default-header hide-default-footer disable-pagination :items="totalList">
          <template v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, i) in items" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                <td>{{item.symbol}}</td>
                <td class="text-right">{{ CommonJS.formatNumber(item.portfolioValue.toFixed(6)) }}</td>
                <td class="text-right">{{ "$" + CommonJS.formatNumber(item.USDValuation.toFixed(2)) }}</td>
              </tr>
              <tr>
                <td>$Total</td>
                <td colspan="2" class="text-right fs-20">{{ "$ " + CommonJS.formatNumber(totalValuation) }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  import AssetsHistogram from '@/highcharts/AssetsHistogram.vue';
  import Bus from "@/components/bus/Bus";
  export default {
    data() {
      return {
        id:"assetsHistogram",
        chartData: [],
        assetsSearchCoinFilterText: null
      }
    },
    props: [ 
      "totalSymbols", "totalList",
    ],
    created() {
    },
    mounted() {

    },
    computed: {
      ...mapGetters(['futuresDecimalNumbers', 'coinSummary', 'responsive','darkMode', 'hideZeroBalances', 'assetsSearchCoinFilter']),
      totalValuation(){
        let chartData = [];
        let total = 0;
        for(let i = 0, len = this.totalList.length; i < len; i++){
          total += this.totalList[i].USDValuation;
          chartData.push(this.totalList[i].USDValuation);
        }
        chartData.push(total);
        this.chartData = chartData;
        let totalAssets = Number(total.toFixed(2));
        this.$store.dispatch('totalAssetsHandler', totalAssets);
        return totalAssets;
      },
    },
    components:{
      AssetsHistogram
    },
    watch: {
      assetsSearchCoinFilterText(val){
        this.$store.dispatch('assetsSearchCoinFilterHandler', val)
      }
    },
    methods: {
      // 改变隐藏零余额的开关
      changeHideZeroBalances(){
        this.$store.dispatch('hideZeroBalancesHandler', !this.hideZeroBalances);
      },
      // 跳转到资金流水页面
      gotoLedger(){

      },
      // 刷新按钮
      refresh(){
        // 总资产
        this.$emit('refreshAssets');
        // 资产趋势图
        Bus.$emit('refreshAssets', null);
      }
    },
  }
</script>
