<template>
  <div>
    <div class="text-center">
        <h5>{{ $t('assets.dailyBenefit') }}</h5>
    </div>
    <div class="mb-2 fs-12">
      <span @click="day=7" class="pointer mr-1" :class="day == 7 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1w') }}</span>
      <span @click="day=30" class="pointer ml-1 mr-1" :class="day == 30 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1m') }}</span>
      <span @click="day=90" class="pointer ml-1 mr-1" :class="day == 90 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.3m') }}</span>
      <span @click="day=365" class="pointer ml-1 mr-1" :class="day == 365 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.1y') }}</span>
      <span @click="day=0" class="pointer ml-1 mr-1" :class="day == 0 ? 'lineblue--text':'grey--text'">{{ $t('highcharts.assetsTrendLineChart.all') }}</span>
      <span class="mr-4 float-right">
        <v-icon size="18" class="lineblue--text mr-2" :title="$t('common.fullScreen')" @click="toggleFullscreen">mdi-fullscreen</v-icon>
      </span>
    </div>
    <div :id="id" class="mr-2 mt-3" style="height: 300px"></div>
  </div>
</template>
<style>
  .lineblue--text{
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
  }
   svg.highcharts-root{
    font-family: "Lexend Deca", sans-serif!important;
  }
</style>
<script>
  import config from '@/config.js';
  import Highcharts from 'highcharts/highstock';
  import { mapGetters } from "vuex";
  import Bus from "@/components/bus/Bus";
  export default {
    data() {
      return {
        day: 90,
        id: 'dailyBenefitColumnChart',
        // 生成的整个图表对象
        chart: null,
        option:{
          // 图表类型
          chart: {
            backgroundColor: this.darkMode ? 'dark' : '',
            renderTo: 'assetsTrendLineChart',
            type: 'column'
          },
          // 标题
          title: {
            text: null,
            align:'left',
            style: {
              color: '#9E9E9E',
            },
          },
          // x轴
          xAxis: {
            categories:[],
            tickInterval: 20,
            type: 'datetime',
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S',
              second: '%m/%d/%Y %H:%M:%S',
              minute: '%m/%d/%Y %H:%M',
              hour: '%m/%d/%Y %H:%M',
              day: '%m/%d',
              week: '%m/%d',
              month: '%m/%Y',
              year: '%Y'
            }
          },
          // y轴
          yAxis: {
            title: {
              text: null
            },
            offset: 40,
            labels: {
              align: "left",
              x: -10
            },
            visible: true,
            // 横线宽度设置为0表示不显示横线
            gridLineWidth: 0,
            opposite:true,
            plotLines: [{
              value: 0,
              color: '#9e9e9e',
              dashStyle: 'shortdash',
              width: 1
            }]
          },
          // 鼠标悬浮提示框
          tooltip: {
            valueDecimals: 2,
            valuePrefix: '$',
            valueSuffix: '',
            followTouchMove: true,
            dateTimeLabelFormats: {
              millisecond: '%m/%d/%Y %H:%M:%S UTC',
              second: '%m/%d/%Y %H:%M:%S UTC',
              minute: '%m/%d/%Y %H:%M UTC',
              hour: '%m/%d/%Y %H:%M UTC',
              day: '%m/%d/%Y %H:%M UTC',
              week: '%m/%d %H:%M UTC',
              month: '%m/%Y',
              year: '%Y'
            },

          },
          // 数据
          series: [
            {
            // type: 'spline',
              name: this.$t('assets.benefit'),
              color:'#4CAF50',
              negativeColor: '#F44336 ',
              borderColor: '',
              data: [],
            },
          ],
          noData: {
            style: {
              fontWeight: 'bold',
              fontSize: '15px',
              color: '#303030'
            }
          },
          // 图例导航
          legend: {
            enabled: false
          },
          // 图形参数
          plotOptions: {
            line: {
              lineWidth: 2,
              cursor: 'pointer',
              marker: {
               enabled: false
             }
            }
          },
          // 版权信息
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false,
            filename: 'Daily-Benefit-Chart',
            buttons: {
              contextButton: {
                align: "left",
                verticalAlign: "top",
                x: 1,
                y: 0
              }
            }
          },
          navigation: {
            buttonOptions: {
              symbolStroke: '#ffffff',
              theme: {
                  fill: this.darkMode ? '' : 'black',
              },
            }
          },
        },
      }
    },
    props:{
      
    },
    watch: {
      day(val){
        this.getAssetsTrend();
      },
      darkMode(val){
        this.option.navigation.buttonOptions.symbolStroke = val ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = val ? '#424242':'white';
        Highcharts.chart(this.id, this.option);
      }
    },
    created(){

    },
    mounted() {
      this.getAssetsTrend();
      Highcharts.setOptions({ lang: {
        noData: this.$t('highcharts.noData'),
        contextButtonTitle: this.$t('highcharts.contextButtonTitle'),
        printChart: this.$t('highcharts.printChart'),
        resetZoom: this.$t('highcharts.resetZoom'),
        resetZoomTitle: this.$t('highcharts.resetZoomTitle'),
        downloadPNG: this.$t('highcharts.downloadPNG'),
        downloadJPEG: this.$t('highcharts.downloadJPEG'),
        downloadPDF: this.$t('highcharts.downloadPDF'),
        downloadSVG: this.$t('highcharts.downloadSVG')
      }});
      this.chart = Highcharts.chart(this.id, this.option);
      Bus.$on('refreshAssets', data => {
        this.getAssetsTrend();
      })
    },
    computed: {
      ...mapGetters(['darkMode', 'responsive'])
    },
    components: {

    },
    methods:{
      getAssetsTrend(){
        this.option.navigation.buttonOptions.symbolStroke = this.darkMode ? 'white':'black';
        this.option.navigation.buttonOptions.theme.fill = this.darkMode ? '#424242':'white';
        this.option.series[0].data = [];
        this.option.xAxis.categories = [];
        let api = config.baseUrl + "/asset/private/getZeroNav?day="+this.day;
        this.$http.get(api).then(res => {
          let data = res.data;
          if (data.code == 200){
            for (let i = 0, len = data.result.length - 1; i < len; i++) {
              this.option.xAxis.categories.push(this.formatDateFunction(data.result[i + 1][0]));
              this.option.series[0].data.push(data.result[i + 1][1] - data.result[i][1]);
            }
            let values = this.option.series[0].data;
            let first = values[0];
            let last = values[values.length - 1];
            // 重新渲染图表
            this.chart = Highcharts.chart(this.id, this.option);
          }
        })
      },
      formatDateFunction(timestamp){
        if (timestamp == 0) {
          return '--';
        } else {
          if( "localTime" == localStorage.getItem("iobots-time")){
            return formatDate(timestamp);
          }else{
            return formatUTCDate(timestamp)
          }
        }
      },
      // 数字格式化
      formatNum(num) {
        return formatNumber(num);
      },
      //全屏
      toggleFullscreen() {
        if (this.chart.fullscreen) {
          this.chart.fullscreen.toggle();
        }
      },
    }
  }
</script>
