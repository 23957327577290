<template>
  <div>
    <!--************************************** FTX ********************************************-->
    <v-card class="pa-5">
        <v-row class="px-2">
          <v-col cols="12" md="12">
            <h3 class="text-center my-0 mb-5">Quasar FTX {{ $t('assets.accounts') }}</h3>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6">
            <h5 class="text-center mb-5">{{$t("assets.spot")}}</h5>
            <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-footer hide-default-header disable-pagination>
                  <template v-slot:body>
                    <tbody>
                      <tr v-for="(item, i) in info.ftxSpot" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                        <td>{{ formatNum(item.symbol) }}</td>
                        <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                        <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                      </tr>
                      <tr>
                        <td>$Total</td>
                        <td></td>
                        <td class="text-right">{{ "$ " + formatNum(ftxSpotTotal) }}</td>
                      </tr>
                    </tbody>
                  </template>
            </v-data-table>
          </v-col>
          <v-col cols="12" lg="6" md="6" sm="6" xs="6">
            <h5 class="text-center mb-5">{{$t("assets.total")}}</h5>
            <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-header hide-default-footer disable-pagination>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in info.ftxTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                    <td>{{ formatNum(item.symbol) }}</td>
                    <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                    <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>$Total</td>
                    <td></td>
                    <td class="text-right">{{ "$" + formatNum(ftxAllTotal) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
    </v-card>
    <!-- *************************************** okex 现货 ***************************************-->
    <v-card class="mt-5 pa-5">
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <h3 class="text-center my-0 mb-5">Quasar OKEx {{ $t('assets.spotAccounts') }}</h3>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
        <h5 class="text-center mb-5">{{$t("assets.funding")}}</h5>
        <v-divider class="my-0"></v-divider>
        <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexFunding" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(okexFundingTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.cash")}}</h5>
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-header hide-default-footer disable-pagination>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in info.okexSpot" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                    <td>{{ formatNum(item.symbol) }}</td>
                    <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                    <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>$Total</td>
                    <td></td>
                    <td class="text-right">{{ "$" + formatNum(okexCashTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.margin")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexMargin" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$ " + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  <!-- <td class="text-right">{{ "0.000000"  }}</td> -->
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$ " + formatNum(okexMarginTotal) }}</td>
                  <!-- <td class="text-right">{{ "$0.00" }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="3">
          <h5 class="text-center mb-5">{{$t("assets.spotTotal")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexSpotTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(okexSpotTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- ************************************** okex 期货 **************************************-->
    <v-card class="mt-5 pa-5">
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <h3 class="text-center my-0 mb-5">Quasar OKEx {{ $t('assets.derivativeAccounts') }}</h3>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.futures")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-header hide-default-footer disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexFutures" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  <!-- <td class="text-right">{{ "$0.000000"  }}</td> -->
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$ " + formatNum(okexFuturesTotal) }}</td>
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
         <h5 class="text-center mb-5">{{$t("assets.perpetualSwap")}}</h5>
         <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexSwap" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(okexSwapTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.options")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexOptions" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  <!-- <td class="text-right">{{ "0.000000"  }}</td> -->
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$ " + formatNum(okexOptionsTotal) }}</td>
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
         <h5 class="text-center mb-5">{{$t("assets.derivativeTotal")}}</h5>
         <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-header hide-default-footer disable-pagination> 
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.okexFuturesTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(okexFuturesAllTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- ************************************* kaken 现货 *************************************-->
    <v-card class="mt-5 pa-5">
      <v-row class="px-2">
        <v-col cols="12" sm="12" md="12">
          <h3 class="text-center my-0 mb-5">Quasar Kraken {{ $t('assets.spotAccounts') }}</h3>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.funding")}}</h5> 
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination> 
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.krakenFunding" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(krakenFundingTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.cash")}}</h5> 
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-header hide-default-footer disable-pagination>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in info.krakenSpot" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                    <td>{{ formatNum(item.symbol) }}</td>
                    <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                    <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>$Total</td>
                    <td></td>
                    <td class="text-right">{{ "$" + formatNum(krakenCashTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.margin")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.krakenMargin" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$ " + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$ " + formatNum(krakenMarginTotal) }}</td>
                  <!-- <td class="text-right">{{ "$0.00" }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
         <h5 class="text-center mb-5">{{$t("assets.spotTotal")}}</h5>
         <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-header hide-default-footer disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.krakenSpotTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(krakenSpotTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!-- ************************************* kaken 期货 *************************************-->
    <v-card class="mt-5 pa-5">
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <h3 class="text-center my-0 mb-5">Quasar Kraken {{ $t('assets.derivativeAccounts') }}</h3>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.futures")}}</h5>
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-footer hide-default-header disable-pagination>
                <template v-slot:body>
                  <tbody>
                    <tr v-for="(item, i) in info.krakenFutures" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                      <td>{{ formatNum(item.symbol) }}</td>
                      <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                      <td class="text-right">{{ "$ " + formatNum(item.USDValuation.toFixed(2)) }}</td>
                      <!-- <td class="text-right">{{ "0.000000"  }}</td> -->
                      <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                    </tr>
                    <tr>
                      <td>$Total</td>
                      <td></td>
                      <td class="text-right">{{ "$ " + formatNum(krakenFuturesTotal) }}</td>
                      <!-- <td class="text-right">{{ "$0.00" }}</td> -->
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.perpetualSwap")}}</h5>
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-header hide-default-footer disable-pagination>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in info.krakenSwap" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                    <td>{{ formatNum(item.symbol) }}</td>
                    <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                    <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>$Total</td>
                    <td></td>
                    <td class="text-right">{{ "$" + formatNum(krakenSwapTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.options")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-footer hide-default-header disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.krakenOptions" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$ " + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  <!-- <td class="text-right">{{ "0.000000"  }}</td> -->
                  <!-- <td class="text-right">{{ "$0.00"  }}</td> -->
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$ " + formatNum(krakenOptionsTotal) }}</td>
                  <!-- <td class="text-right">{{ "$0.00" }}</td> -->
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.derivativeTotal")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-header hide-default-footer disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.krakenFuturesTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(krakenFuturesAllTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!--************************************** Binance ********************************************-->
    <!-- Binance 期货 -->
    <v-card class="mt-5 pa-5">
      <v-row class="px-2">
        <v-col cols="12" md="12">
          <h3 class="text-center my-0 mb-5">Quasar Binance {{ $t('assets.accounts') }}</h3>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.spot")}}</h5>
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-footer hide-default-header disable-pagination>
                <template v-slot:body>
                  <tbody>
                    <tr v-for="(item, i) in info.binanceSpot" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                      <td>{{ formatNum(item.symbol) }}</td>
                      <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                      <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                    </tr>
                    <tr>
                      <td>$Total</td>
                      <td></td>
                      <td class="text-right">{{ "$ " + formatNum(binanceSpotTotal) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-data-table>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.perpetualSwap")}}</h5>
          <v-divider class="my-0"></v-divider>
            <v-data-table dense hide-default-header hide-default-footer disable-pagination>
              <template v-slot:body>
                <tbody>
                  <tr v-for="(item, i) in info.binanceSwap" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                    <td>{{ formatNum(item.symbol) }}</td>
                    <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                    <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                  </tr>
                  <tr>
                    <td>$Total</td>
                    <td></td>
                    <td class="text-right">{{ "$" + formatNum(binanceSwapTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12" lg="4" md="6" sm="6" xs="6">
          <h5 class="text-center mb-5">{{$t("assets.total")}}</h5>
          <v-divider class="my-0"></v-divider>
          <v-data-table dense hide-default-header hide-default-footer disable-pagination>
            <template v-slot:body>
              <tbody>
                <tr v-for="(item, i) in info.binanceTotal" :key="i" v-if="(!hideZeroBalances || item.portfolioValue > 0) && CommonJS.likeMatch(item.symbol, assetsSearchCoinFilter)">
                  <td>{{ formatNum(item.symbol) }}</td>
                  <td class="text-right">{{ formatNum(item.portfolioValue.toFixed(6)) }}</td>
                  <td class="text-right">{{ "$" + formatNum(item.USDValuation.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td>$Total</td>
                  <td></td>
                  <td class="text-right">{{ "$" + formatNum(binanceAllTotal) }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
  import config from '@/config.js';
  import { mapGetters } from "vuex";
  export default {
    data() {
      return {
      }
    },
    props:["info"],
    created() {
    },
    mounted() {

    },
    computed: {
      ...mapGetters(['futuresDecimalNumbers', 'coinSummary', 'responsive','darkMode', 'hideZeroBalances', 'assetsSearchCoinFilter']),
      okexSpotTotal(){
        var total = 0;
        if(this.info.okexSpotTotal.length > 0){
          for(var i = 0 ; i < this.info.okexSpotTotal.length ; i++){
            total += this.info.okexSpotTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexFundingTotal(){
        var total = 0;
        if(this.info.okexFunding.length > 0){
          for(var i = 0 ; i < this.info.okexFunding.length ; i++){
            total += this.info.okexFunding[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexMarginTotal(){
        var total = 0;
        if(this.info.okexMargin.length > 0){
          for(var i = 0 ; i < this.info.okexMargin.length ; i++){
            total += this.info.okexMargin[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexCashTotal(){
        var total = 0;
        if(this.info.okexSpot.length > 0){
          for(var i = 0 ; i < this.info.okexSpot.length ; i++){
            total += this.info.okexSpot[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexFuturesTotal(){
        var total = 0;
        if(this.info.okexFutures.length > 0){
          for(var i = 0 ; i < this.info.okexFutures.length ; i++){
            total += this.info.okexFutures[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexOptionsTotal(){
        var total = 0;
        if(this.info.okexOptions.length > 0){
          for(var i = 0 ; i < this.info.okexOptions.length ; i++){
            total += this.info.okexOptions[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexFuturesAllTotal(){
        var total = 0;
        if(this.info.okexFuturesTotal.length > 0){
          for(var i = 0 ; i < this.info.okexFuturesTotal.length ; i++){
            total += this.info.okexFuturesTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      okexSwapTotal(){
        var total = 0;
        if(this.info.okexSwap.length > 0){
          for(var i = 0 ; i < this.info.okexSwap.length ; i++){
            total += this.info.okexSwap[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenSpotTotal(){
        var total = 0;
        if(this.info.krakenSpotTotal.length > 0){
          for(var i = 0 ; i < this.info.krakenSpotTotal.length ; i++){
            total += this.info.krakenSpotTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenFundingTotal(){
        var total = 0;
        if(this.info.krakenFunding.length > 0){
          for(var i = 0 ; i < this.info.krakenFunding.length ; i++){
            total += this.info.krakenFunding[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenMarginTotal(){
        var total = 0;
        if(this.info.krakenMargin.length > 0){
          for(var i = 0 ; i < this.info.krakenMargin.length ; i++){
            total += this.info.krakenMargin[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenCashTotal(){
        var total = 0;
        if(this.info.krakenSpot.length > 0){
          for(var i = 0 ; i < this.info.krakenSpot.length ; i++){
            total += this.info.krakenSpot[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenFuturesAllTotal(){
        var total = 0;
        if(this.info.krakenFuturesTotal.length > 0){
          for(var i = 0 ; i < this.info.krakenFuturesTotal.length ; i++){
            total += this.info.krakenFuturesTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenFuturesTotal(){
        var total = 0;
        if(this.info.krakenFutures.length > 0){
          for(var i = 0 ; i < this.info.krakenFutures.length ; i++){
            total += this.info.krakenFutures[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenSwapTotal(){
        var total = 0;
        if(this.info.krakenSwap.length > 0){
          for(var i = 0 ; i < this.info.krakenSwap.length ; i++){
            total += this.info.krakenSwap[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      krakenOptionsTotal(){
        var total = 0;
        if(this.info.krakenOptions.length > 0){
          for(var i = 0 ; i < this.info.krakenOptions.length ; i++){
            total += this.info.krakenOptions[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      binanceSpotTotal(){
        var total = 0;
        if(this.info.binanceSpot.length > 0){
          for(var i = 0 ; i < this.info.binanceSpot.length ; i++){
            total += this.info.binanceSpot[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      binanceSwapTotal(){
        var total = 0;
        if(this.info.binanceSwap.length > 0){
          for(var i = 0 ; i < this.info.binanceSwap.length ; i++){
            total += this.info.binanceSwap[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      binanceAllTotal(){
        var total = 0;
        if(this.info.binanceTotal.length > 0){
          for(var i = 0 ; i < this.info.binanceTotal.length ; i++){
            total += this.info.binanceTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      ftxSpotTotal(){
        var total = 0;
        if(this.info.ftxSpot.length > 0){
          for(var i = 0 ; i < this.info.ftxSpot.length ; i++){
            total += this.info.ftxSpot[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      ftxAllTotal(){
        var total = 0;
        if(this.info.ftxTotal.length > 0){
          for(var i = 0 ; i < this.info.ftxTotal.length ; i++){
            total += this.info.ftxTotal[i].USDValuation;
          }
        }
        return total.toFixed(2);
      },
      // *********************************************底部*********************************************
    },
    watch: {

    },
    methods: {
      
      // 数字格式化
      formatNum(num) {
        return formatNumber(num);
      },
    },
  }
</script>
